import { useEffect, useState } from 'react'
import { useNavigate, Link, useFetcher } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  ToggleButton,
  Button,
} from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { formatDate } from '@trinity/utils'
import { CollectionCard, Dialog, Pagination, Search, Table, ToggleButtonGroup } from '../../components'
import { useFilters, useGlobalState } from '../../hooks'

export function StyleflowBrowse({ type, ...props }: StyleflowBrowseProps) {
  if (type === 'collections') {
    return <BrowseCollections {...(props as StyleflowBrowseCollectionsProps)} />
  }

  if (type === 'stylepages') {
    return <BrowseStylepages {...(props as StyleflowBrowseStylepagesProps)} />
  }

  return null
}

//* COMPONENTS
function BrowseHeader({ type, openDrawer = () => null }: BrowseHeaderProps) {
  const navigate = useNavigate()
  const [view, setView] = useState(type === COLLECTIONS ? COLLECTIONS : STYLEPAGES)

  return (
    <Stack spacing={4}>
      <Stack
        direction={{ mobile: 'row', laptop: 'row' }}
        spacing={4}
        justifyContent={{ mobile: 'center', laptop: 'space-between' }}
        alignItems='center'
        pt={{ mobile: 6, laptop: 0 }}
      >
        <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={4} alignItems='center'>
          <Typography variant='h1'>Styleflow</Typography>
          <ToggleButtonGroup
            exclusive
            size='medium'
            value={view}
            onChange={(_e, v) => {
              if (v) {
                setView(v)
                navigate(`/styleflow/${v}`)
              }
            }}
            sx={{ width: 'fit-content', alignSelf: 'center' }}
          >
            <ToggleButton value={COLLECTIONS}>Collections</ToggleButton>
            <ToggleButton value={STYLEPAGES}>Stylepages</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1} width={{ mobile: 1, laptop: 350 }}>
        <Search<TrinityAPI.CollectionType | TrinityAPI.VisualizerStylepageType>
          name='search'
          placeholder={`Search ${type}...`}
        >
          {({ suggestion, searchTerm }) => <ItemSuggestion suggestion={suggestion} searchTerm={searchTerm} />}
        </Search>
        {type === 'collections' && (
          <IconButton
            color='primary'
            onClick={openDrawer}
            sx={{ border: 'primary', borderRadius: 1, px: 2.5, display: { laptop: 'none' } }}
          >
            <FilterList />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

function BrowseStylepages({ stylepages, totalStylepages }: StyleflowBrowseStylepagesProps) {
  const { onMobile } = useGlobalState()
  const navigate = useNavigate()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [itemId, setItemId] = useState<number>()
  const handleClose = () => setShowConfirmation(false)
  const handleOpen = (id: number) => {
    setShowConfirmation(true)
    setItemId(id)
  }

  return (
    <Stack spacing={4}>
      <BrowseHeader type='stylepages' />
      <Divider />
      {stylepages.length < 1 ? (
        <Stack spacing={4} alignItems='center'>
          <Typography variant='h6'>No Stylepages Found.</Typography>
          <Typography>
            To start a new Stylepage, browse collections and add items to the Rack. From the rack, you will be able to
            save them as a new Stylepage. (This is placeholder text)
          </Typography>
        </Stack>
      ) : (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              {!onMobile && <Table.Cell>Date Created</Table.Cell>}
              <Table.Cell>Items</Table.Cell>
              <Table.Cell>Actions</Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {stylepages.map(stylepage => {
              if (!stylepage) return null

              return (
                <Table.Row key={stylepage.id}>
                  <Table.Cell>{stylepage.name}</Table.Cell>
                  {!onMobile && <Table.Cell>{formatDate(stylepage.createdAt)}</Table.Cell>}
                  <Table.Cell>{stylepage.items.length}</Table.Cell>
                  <Table.Cell>
                    <Stack direction='row' spacing={1}>
                      <Button
                        size='small'
                        variant='outlined'
                        color='neutral'
                        onClick={() => navigate(String(stylepage.id))}
                        sx={{ mr: 2 }}
                      >
                        View
                      </Button>
                      <Button size='small' variant='outlined' color='neutral' onClick={() => handleOpen(stylepage.id)}>
                        Delete
                      </Button>
                    </Stack>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )}
      {itemId && <ConfirmationDialog id={itemId} open={showConfirmation} handleClose={handleClose} />}
      <Pagination total={totalStylepages} pageSizeOptions={[24, 48, 96]} sx={{ pt: 5 }} />
    </Stack>
  )
}

function BrowseCollections({ filters, collections, totalCollections }: StyleflowBrowseCollectionsProps) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { Filters, FilterChips } = useFilters({
    open: drawerOpen,
    close: () => setDrawerOpen(false),
    filters: filters,
  })
  return (
    <Stack direction='row' spacing={4}>
      <Filters />
      <Stack spacing={4} width={1}>
        <BrowseHeader type='collections' openDrawer={() => setDrawerOpen(true)} />
        <Divider />
        <FilterChips />
        {collections.length < 1 ? (
          <Typography textAlign='center' variant='h4'>
            No collections found
          </Typography>
        ) : (
          <Grid
            container
            alignItems='center'
            justifyContent={{ mobile: 'center', tablet: 'space-evenly' }}
            spacing={2}
            mt={4}
          >
            {collections.map(collection => (
              <Grid key={collection.id}>
                <CollectionCard collection={collection} />
              </Grid>
            ))}
          </Grid>
        )}
        <Pagination total={totalCollections} pageSizeOptions={[24, 48, 96]} sx={{ pt: 5 }} />
      </Stack>
    </Stack>
  )
}

function ConfirmationDialog({ id, open, handleClose }: ConfirmationDialogProps) {
  const { setToastInfo } = useGlobalState()
  const fetcher = useFetcher<{ message: string }>()

  useEffect(() => {
    if (fetcher.data?.message) {
      setToastInfo({ show: true, severity: 'success', message: fetcher.data.message })
    }
  }, [fetcher.data, setToastInfo])

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack spacing={4} p={4}>
        <Typography variant='h3' align='center'>
          Please Confirm
        </Typography>
        <Typography>This stylepage and all related items will be permanantly deleted.</Typography>
        <Stack direction='row' spacing={2} justifyContent='flex-end'>
          <Button size='small' variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size='small'
            color='error'
            type='submit'
            name='intent'
            value='delete'
            onClick={() => {
              handleClose()
              fetcher.submit({ id, intent: 'delete' }, { method: 'POST' })
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

//* HELPERS
const COLLECTIONS = 'collections'
const STYLEPAGES = 'stylepages'

function ItemSuggestion({
  suggestion,
  searchTerm,
}: {
  suggestion: TrinityAPI.CollectionType | TrinityAPI.VisualizerStylepageType
  searchTerm: string
}) {
  if (!suggestion) return null

  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={String(suggestion.id)}>
        <ListItemText primaryTypographyProps={{ variant: 'body3' }}>
          <Highlighter searchWords={[searchTerm]} textToHighlight={suggestion.name} highlightTag='strong' />
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

//* TYPES
type StyleflowBrowseProps =
  | ({
      type: typeof STYLEPAGES
    } & StyleflowBrowseStylepagesProps)
  | ({
      type: typeof COLLECTIONS
    } & StyleflowBrowseCollectionsProps)

interface StyleflowBrowseStylepagesProps {
  stylepages: TrinityAPI.VisualizerStylepageType[]
  totalStylepages: number
}

interface StyleflowBrowseCollectionsProps {
  filters: TrinityAPI.FiltersType
  collections: TrinityAPI.CollectionType[]
  totalCollections: number
}

interface BrowseHeaderProps {
  type: typeof COLLECTIONS | typeof STYLEPAGES
  openDrawer?: () => void
}

interface ConfirmationDialogProps {
  id: number
  open: boolean
  handleClose: () => void
}

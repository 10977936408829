import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { capitalCase, type MaterialTypes } from '@trinity/utils'
import { useNavigate } from 'react-router-dom'
import { useExcludeSearchParams, useWindowSize } from '../../hooks'

interface BottomActionBarProps {
  lastMaterial?: MaterialTypes
  nextMaterial?: MaterialTypes
}

export function BottomActionBar({ lastMaterial = undefined, nextMaterial = undefined }: BottomActionBarProps) {
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const leftPosition = (width + 65) / 2
  // const rightPosition = Math.max(leftPosition - 657, 93)
  const validParams = useExcludeSearchParams(['page', 'q', 'perPage'])

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      position='fixed'
      p={4}
      bottom={{ mobile: 0, laptop: 40 }}
      left={{ mobile: 0, laptop: leftPosition }}
      right={{ mobile: 0, laptop: 90 }}
      bgcolor='rgba(255, 255, 255, 0.80)'
    >
      <Button
        disabled={!lastMaterial}
        variant='text'
        color='neutral'
        startIcon={<KeyboardArrowLeft />}
        onClick={() => navigate(`../${lastMaterial}?${validParams}`, { replace: true })}
        sx={{ '&:hover': { bgcolor: 'unset' } }}
      >
        {capitalCase(lastMaterial ?? '')}
      </Button>
      <Button sx={{ borderRadius: 5, opacity: 1 }} onClick={() => navigate(`..?${validParams}`, { replace: true })}>
        Done
      </Button>
      <Button
        disabled={!nextMaterial}
        variant='text'
        color='neutral'
        endIcon={<KeyboardArrowRight />}
        onClick={() => navigate(`../${nextMaterial}?${validParams}`, { replace: true })}
        sx={{ '&:hover': { bgcolor: 'unset' } }}
      >
        {capitalCase(nextMaterial ?? '')}
      </Button>
    </Stack>
  )
}

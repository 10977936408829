import { Stack, Typography } from '@mui/material'
import { TrendingDown, TrendingUp } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { Expand } from '../Animations'
import { EmptyCard } from '../Cards'

interface DealerRankChipProps {
  rank: number
  revenue: number
  percentile: number
}

export function DealerRankChip({ rank, revenue, percentile }: DealerRankChipProps) {
  const isPositive = percentile > 0
  const Icon = isPositive ? TrendingUp : TrendingDown
  const color = isPositive ? 'success' : 'error'
  const ranking = revenue === 0 ? 'N/A' : rank

  return (
    <Expand>
      <Link to='/reports/rank' style={{ textDecoration: 'none' }}>
        <EmptyCard direction='row' spacing={2} alignItems='center' py={1} width='fit-content'>
          <Typography variant='body3'>
            Dealer Rank: <strong>{ranking}</strong>
          </Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Icon color={color} />
            <Typography variant='body3'> Vs Last Month</Typography>
          </Stack>
        </EmptyCard>
      </Link>
    </Expand>
  )
}

import { useState, useEffect, useCallback } from 'react'
import { useSearchParams, useFetchers } from 'react-router-dom'
import { Divider, MenuItem, Stack, TextField, ToggleButton, Typography } from '@mui/material'
import { UnfoldMore } from '@mui/icons-material'
import {
  BREEK,
  CLOTHING_GARMENTS,
  COAT_PANT_PANT,
  COAT_VEST_PANT_PANT,
  COMPOSED_GARMENTS,
  SHIRT,
  SWACKET,
  uniqArray,
} from '@trinity/utils'
import { BackButton, FabricCardList, ImageCardList, ToggleButtonGroup } from '../../components'
import { useGlobalState } from '../../hooks'

export function StyleflowCollection({ collection, fabrics }: StyleflowCollectionProps) {
  const { onMobile, onTablet, setToastInfo } = useGlobalState()
  const [params, setParams] = useSearchParams()
  const fetchers = useFetchers()
  const fetcher = fetchers.find(fetcher => fetcher.key === ImageCardList.FETCHER_KEY)
  const [view, setView] = useState(params.get('view') ?? SWATCHES)
  const [currentFabrics, setCurrentFabrics] = useState(getDefaultFabrics(fabrics))
  const isGarmentView = view === GARMENTS
  const isMobile = onMobile || onTablet
  const handleSetView = (view: string) => {
    setParams(params => {
      params.set('view', view)
      return params
    })
    setView(view)
  }

  useEffect(() => {
    if (fetcher?.data) {
      setToastInfo({ show: true, severity: fetcher.data.error ? 'error' : 'success', message: fetcher?.data?.message })
    }
  }, [fetcher, setToastInfo])

  return (
    <Stack spacing={4}>
      <BackButton to='/styleflow/collections' />
      <Header collection={collection} />
      {isMobile && <ToggleView view={view} setView={handleSetView} />}
      <Divider />
      <Stack direction='row' justifyContent={{ mobile: 'center', laptop: 'space-between' }}>
        {isGarmentView ? <Filters fabrics={fabrics} setFabrics={setCurrentFabrics} /> : <div />}
        {!isMobile && <ToggleView view={view} setView={handleSetView} />}
      </Stack>
      <FabricImageList fabrics={currentFabrics} isGarmentView={isGarmentView} collectionFabrics={collection.fabrics} />
    </Stack>
  )
}

function Header({ collection }: HeaderProps) {
  return (
    <Stack direction='row' justifyContent={{ mobile: 'center', laptop: 'space-between' }}>
      <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 1, laptop: 4 }} justifyContent='center'>
        <img src={collection.image} alt={collection.name} height={100} width={100} />
        <div>
          <Typography gutterBottom variant='h1'>
            {collection.brand ?? collection.title}
          </Typography>
          <Typography variant='body1'>{collection.title}</Typography>
        </div>
      </Stack>
    </Stack>
  )
}

function Filters({ fabrics, setFabrics }: FiltersProps) {
  const [params, setParams] = useSearchParams()
  const [garmentType, validGarmentTypes] = getValidGarmentTypes(fabrics)
  const models = getModels(fabrics)
  const [model, setModel] = useState(models[0])
  const newGarmentTypeSet = !model || !models.includes(model)

  const handleModelChange = useCallback(
    (model?: string) => {
      const newFabrics = getDefaultFabrics(fabrics, model)

      setModel(model)
      setFabrics(newFabrics)

      setParams(params => {
        if (model) params.set('model', model)

        return params
      })
    },
    [fabrics, setFabrics, setParams],
  )

  useEffect(() => {
    const paramModel = params.get('model')
    if (paramModel && model !== paramModel) {
      handleModelChange(paramModel)
    }
  }, [handleModelChange, model, params])

  useEffect(() => {
    if (newGarmentTypeSet) {
      handleModelChange(models[0])
    }
  }, [handleModelChange, models, newGarmentTypeSet])

  if (newGarmentTypeSet) return <div />

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 2, laptop: 4 }}>
      <TextField
        select
        size='small'
        name='garmentType'
        label='Garment Type'
        SelectProps={{ IconComponent: UnfoldMore }}
        value={garmentType?.bitmask}
        sx={{ width: 200 }}
        onChange={e =>
          setParams(params => {
            params.set('garmentType', e.target.value)
            params.delete('model')
            return params
          })
        }
      >
        {validGarmentTypes.map(type => (
          <MenuItem key={type.bitmask} value={type.bitmask}>
            {type.alternateName ?? type.formalName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        size='small'
        name='model'
        label='Model'
        value={model}
        sx={{ width: 200 }}
        onChange={e => handleModelChange(e.target.value)}
      >
        {models.map(model => (
          <MenuItem key={model} value={model}>
            {model}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  )
}

function ToggleView({ view, setView }: ToggleViewProps) {
  return (
    <ToggleButtonGroup
      exclusive
      size='medium'
      value={view}
      onChange={(_e, v) => v && setView(v)}
      sx={{ width: 'fit-content', alignSelf: 'center' }}
    >
      <ToggleButton value={SWATCHES}>Swatches</ToggleButton>
      <ToggleButton value={GARMENTS}>Garments</ToggleButton>
    </ToggleButtonGroup>
  )
}

function FabricImageList({ fabrics, collectionFabrics, isGarmentView = false }: FabricImageListProps) {
  const collectionId = collectionFabrics[0]?.collectionId ?? ''

  if (fabrics.length < 1) {
    return (
      <Typography variant='h6' align='center' width={1} pt={4}>
        No Images Found For This Collection
      </Typography>
    )
  }

  if (isGarmentView) {
    const items = fabrics.map(fabric => ({
      key: fabric.id,
      image: fabric.image,
      dividerText: fabric.baseModel.description,
      primaryText: `${fabric.fabric?.trinityNumber} (${fabric.position})`,
      item: fabric,
    }))
    return (
      <ImageCardList items={items} actions={['add', 'edit']}>
        {fabric => (
          <>
            <input type='hidden' name='baseModelId' value={fabric.baseModel.id} />
            <input type='hidden' name='fabricId' value={fabric.fabric?.id} />
            <input type='hidden' name='garmentType' value={fabric.garmentType.bitmask} />
            <input type='hidden' name='imageUri' value={fabric.image} />
            <input type='hidden' name='liningId' value={fabric.lining?.id} />
            <input type='hidden' name='buttonId' value={fabric.button?.id} />
            <input type='hidden' name='threadId' value={fabric.thread?.id} />
            <input type='hidden' name='ribknitId' value={fabric.ribknit?.id} />
            <input type='hidden' name='zipperId' value={fabric.zipper?.id} />
            <input type='hidden' name='collectionId' value={collectionId} />
            <input type='hidden' name='model' value={fabric.baseModel.description} />
          </>
        )}
      </ImageCardList>
    )
  }

  const swatchFabrics = collectionFabrics.map(fabric => {
    const fabricImage = fabrics.find(f => f.fabric?.id === fabric.id)
    return { ...fabric, position: fabricImage?.position }
  })
  const firstFabric = fabrics[0]
  const baseModelId = firstFabric?.baseModel.id
  const garmentType = firstFabric?.garmentType?.bitmask

  return (
    <FabricCardList
      isBasic
      fabrics={swatchFabrics}
      baseUrl={`/styleflow/visualize?fabricId=&baseModelId=${baseModelId}&garmentType=${garmentType}&referrer=collections/${collectionId}`}
    />
  )
}

//* HELPERS
const SWATCHES = 'swatches'
const GARMENTS = 'garments'

const getModels = (fabrics: TrinityAPI.FabricImageType[]) =>
  uniqArray(fabrics.map(fabric => fabric.baseModel.description))

const getDefaultFabrics = (fabrics: TrinityAPI.FabricImageType[], baseModel?: string) => {
  let model = baseModel

  if (!model) {
    const models = getModels(fabrics)
    model = models[0]
  }

  return fabrics.filter(fabric => fabric.baseModel.description === model)
}

const getValidGarmentTypes = (
  fabrics: TrinityAPI.FabricImageType[],
): [TrinityAPI.GarmentTypesType, TrinityAPI.GarmentTypesType[]] => {
  const ignoredGarmentTypes: TrinityAPI.GarmentTypesType[] = [COAT_PANT_PANT, COAT_VEST_PANT_PANT, BREEK, SWACKET]
  const currentGarmentType = fabrics[0]?.garmentType
  const baseGarmentTypes = currentGarmentType === SHIRT ? [SHIRT] : [...CLOTHING_GARMENTS, ...COMPOSED_GARMENTS]
  const filteredGarmentTypes = baseGarmentTypes.filter(type => !ignoredGarmentTypes.includes(type))

  if (!currentGarmentType) throw new Error('Invalid garment type')

  return [currentGarmentType, filteredGarmentTypes]
}

//* TYPES
interface StyleflowCollectionProps {
  collection: TrinityAPI.CollectionExtendedType
  fabrics: TrinityAPI.FabricImageType[]
}

type FabricsPropType = Pick<StyleflowCollectionProps, 'fabrics'>
type HeaderProps = Pick<StyleflowCollectionProps, 'collection'>

interface FiltersProps extends FabricsPropType {
  setFabrics: (fabrics: TrinityAPI.FabricImageType[]) => void
}

interface ToggleViewProps {
  view: string
  setView: (view: string) => void
}

interface FabricImageListProps extends FabricsPropType {
  collectionFabrics: TrinityAPI.FabricExtendedType[]
  isGarmentView?: boolean
}

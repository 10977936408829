import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Stack, ToggleButton, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { type MaterialTypes } from '@trinity/utils'
import { MaterialCard, Search, ToggleButtonGroup } from '../../components'
import { BottomActionBar } from './helpers'

interface StyleflowVisualizeButtonsProps {
  buttons: TrinityAPI.ButtonType[]
  materialList: MaterialTypes[]
}

export function StyleflowVisualizeButtons({ buttons, materialList }: StyleflowVisualizeButtonsProps) {
  const buttonIndex = materialList.findIndex(material => material === 'button')

  return (
    <Stack spacing={4} pb={14}>
      <ButtonHeader />
      <Stack px={{ mobile: 0, laptop: 1 }}>
        <Search name='q' method='get' />
      </Stack>
      <ButtonList buttons={buttons} />
      <BottomActionBar lastMaterial={materialList[buttonIndex - 1]} nextMaterial={materialList[buttonIndex + 1]} />
    </Stack>
  )
}

function ButtonHeader() {
  const [buttonType, setButtonType] = useState('all')
  const [_params, setParams] = useSearchParams()

  return (
    <Stack
      spacing={4}
      direction={{ mobile: 'column', laptop: 'row' }}
      alignItems='center'
      justifyContent='space-between'
      px={1}
    >
      <Typography variant='h5'>Buttons</Typography>
      <ToggleButtonGroup
        value={buttonType}
        onChange={(_e, v) => {
          if (v) {
            setButtonType(v)
            setParams(params => {
              params.set('priceTier', v)
              return params
            })
          }
        }}
        sx={{ width: 'fit-content' }}
      >
        <ToggleButton value='all'>All</ToggleButton>
        <ToggleButton value='1'>Standard</ToggleButton>
        <ToggleButton value='2'>Premium</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

function ButtonList({ buttons }: { buttons: TrinityAPI.ButtonType[] }) {
  const [params, setParams] = useSearchParams()

  return (
    <Grid container spacing={2} alignItems='center' justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
      {buttons.map(button => (
        <Grid key={button.value}>
          <MaterialCard
            onClick={() =>
              setParams(params => {
                params.set('buttonId', String(button.id))
                return params
              })
            }
            image={button.image}
            headerText={button.code ?? ''}
            subText={button.description}
            selected={String(button.id) === params.get('buttonId')}
          />
        </Grid>
      ))}
    </Grid>
  )
}
